const getApiHeaders = () => {
  // TODO: should add some kind of auth token here

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
}

interface CompanyTableRow {
  label: string
}

const getCompanyTableRowsFromApi = async (): Promise<any> => {
  const headers = getApiHeaders()
  const response = await fetch('/api/table/companies', {
    method: 'GET',
    headers,
  })

  return await response.json()
}

type APIResponseType = {
  data: any
  status: string
}

interface CompanyAPIResponse {
  label: string
}

const getCompanyFromApi = async (ticker: string): Promise<CompanyAPIResponse> => {
  const headers = getApiHeaders()
  const response = await fetch(`/api/company/${ticker}`, {
    method: 'GET',
    headers,
  })

  return await response.json()
}

export { getCompanyTableRowsFromApi, getCompanyFromApi }
