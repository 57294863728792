import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import './App.css'
import { CompanyTable } from './Components/CompanyTable'
import {
  Routes,
  Route,
  Outlet,
  Link,
  useParams,
  useLoaderData,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import { getCompanyFromApi, getCompanyTableRowsFromApi } from './Services/apiService'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'c/:short',
        element: <ListCompany />,
        loader: ({ params }) => listCompanyLoader(params.short ?? ''),
      },
      {
        path: '*',
        element: <NoMatch />,
      },
    ],
  },
])

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  )
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </nav>

      <hr />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  )
}

function Home() {
  return (
    <div>
      <Container maxWidth="lg">
        <CompanyTable />
      </Container>
    </div>
  )
}

async function listCompanyLoader(short: string) {
  const company = await getCompanyFromApi(short)
  return company
}

interface ListCompany {
  label: string
  ticker: string
  price: number
}

function ListCompany() {
  const params = useParams()
  const listCompany = useLoaderData() as ListCompany

  return (
    <div>
      <h2>Company {listCompany.label}</h2>
    </div>
  )
}

function NoMatch() {
  return (
    <div>
      <h2>Nic tu nie ma</h2>
      <p>
        <Link to="/">Powrót</Link>
      </p>
    </div>
  )
}

export default App
