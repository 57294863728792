import SearchBar from 'material-ui-search-bar'
import { useState } from 'react'

interface CompanySearchBarProps {
  handleSearch: (value: string) => void
}

export const CompanySearchBar = (props: CompanySearchBarProps) => {
  const [value, setValue] = useState<string>('')

  return (
    <SearchBar
      value={value}
      onChange={(newValue: string) => {
        props.handleSearch(newValue)
        setValue(newValue)
      }}
      onRequestSearch={() => props.handleSearch(value)}
    />
  )
}
