import { Box } from '@mui/system'
import React, { ElementType, useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { CircularProgress, formLabelClasses, TablePagination, TableSortLabel } from '@mui/material'
import { visuallyHidden } from '@mui/utils'
// import { styled } from '@mui/system';
import { styled } from '@mui/material/styles'
import { renderPrettyMarketCap } from '../Utils/strings'
import { getCompanyTableRowsFromApi } from '../Services/apiService'
import { CompanySearchBar } from './CompanySearchBar'
import { TableCellBaseProps } from '@material-ui/core'
import { Link } from 'react-router-dom'

type CompanyTableRow = {
  name: string
  tickerSymbol: string
  marketCap: number
  price: number
  change: number
  volumeInPLN: number
  change24h: string
  change7d: string
  change30d: string
}
const testCompanies: CompanyTableRow[] = [
  // {
  //   name: 'Allegro',
  //   tickerSymbol: 'ALE',
  //   marketCap: 100000000,
  //   price: 50.2,
  //   change: 1.1
  // },
  // {
  //   name: 'Test',
  //   tickerSymbol: 'TST',
  //   marketCap: 200000000,
  //   price: 20,
  //   change: -0.2
  // }
]

type ExtraProps = {
  component: React.ElementType
}
const CompanyTableContainer = styled(TableContainer)<ExtraProps>({
  color: 'darkslategray',
  backgroundColor: 'aliceblue',
  padding: 8,
  borderRadius: 4,
  marginTop: '2em',
  marginBottom: '2em',
})

const PriceChangeSpan = styled('span')`
  &.increase {
    color: green;
  }
  &.decrease {
    color: red;
  }
`

type Order = 'asc' | 'desc'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export const CompanyTable = () => {
  const [companyTableRows, setCompanyTableRows] = useState<CompanyTableRow[]>(testCompanies)
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true)
  const [totalNumberOfPages, setTotalNumberOfPages] = useState<number>(5)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')

  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof CompanyTableRow>('marketCap')

  const handleChangePage = (event: any, newPage: number) => {
    setCurrentPage(newPage)
  }

  useEffect(() => {
    async function loadCompanyTableRows() {
      setCompanyTableRows(await getCompanyTableRowsFromApi())
      setIsTableLoading(false)
    }

    loadCompanyTableRows()
  }, [])

  const rowsPerPage = 100
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = currentPage > 0 ? Math.max(0, (1 + currentPage) * rowsPerPage - companyTableRows.length) : 0;

  // sorting
  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const createSortHandler = (property: keyof CompanyTableRow) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property)
  }

  interface HeaderData {
    id: keyof CompanyTableRow
    label: string
  }
  const tableHeaderData: HeaderData[] = [
    { id: 'marketCap', label: 'Kapitalizacja' },
    { id: 'volumeInPLN', label: 'Obrót' },
    { id: 'price', label: 'Cena' },
    { id: 'change24h', label: 'Zmiana 24h' },
    { id: 'change7d', label: 'Zmiana 7d' },
    { id: 'change30d', label: 'Zmiana 30d' },
  ]

  return (
    <CompanyTableContainer component={Paper}>
      {isTableLoading ? (
        <CircularProgress />
      ) : (
        <>
          <CompanySearchBar
            handleSearch={(value: string) => {
              setSearchValue(value)
            }}
          />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Pozycja</TableCell>
                <TableCell>Nazwa</TableCell>
                <TableCell align="right">Symbol</TableCell>

                {tableHeaderData.map((tableHeader) => (
                  <TableCell key={tableHeader.id}>
                    <TableSortLabel
                      active={orderBy === tableHeader.id}
                      direction={orderBy === tableHeader.id ? order : 'asc'}
                      onClick={createSortHandler(tableHeader.id)}
                    >
                      {tableHeader.label}
                      {orderBy === tableHeader.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(searchValue && searchValue.length > 2
                ? stableSort(companyTableRows, getComparator(order, orderBy))
                    .filter((row: CompanyTableRow) => {
                      return (
                        row.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                        row.tickerSymbol.toLowerCase().includes(searchValue.toLowerCase())
                      )
                    })
                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                : stableSort(companyTableRows, getComparator(order, orderBy))
                    .slice()
                    .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
              ).map((row: CompanyTableRow, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{index + 1 + currentPage * rowsPerPage}</TableCell>
                  <TableCell scope="row">
                    <Link to={`/c/${row.tickerSymbol}`}>{row.name}</Link>
                  </TableCell>
                  <TableCell align="right">{row.tickerSymbol}</TableCell>
                  <TableCell align="right">{renderPrettyMarketCap(row.marketCap)}</TableCell>
                  <TableCell align="right">{row.volumeInPLN}</TableCell>
                  <TableCell align="right">{row.price}</TableCell>
                  <TableCell align="right">
                    <PriceChangeSpan
                      className={
                        parseFloat(row.change24h) > 0
                          ? 'increase'
                          : parseFloat(row.change24h) === 0
                          ? 'neutral'
                          : 'decrease'
                      }
                    >
                      {row.change24h}
                    </PriceChangeSpan>
                  </TableCell>
                  <TableCell align="right">
                    <PriceChangeSpan
                      className={
                        parseFloat(row.change7d) ? 'increase' : parseFloat(row.change7d) === 0 ? 'neutral' : 'decrease'
                      }
                    >
                      {row.change7d}
                    </PriceChangeSpan>
                  </TableCell>
                  <TableCell align="right">
                    <PriceChangeSpan
                      className={
                        parseFloat(row.change30d) > 0
                          ? 'increase'
                          : parseFloat(row.change30d) === 0
                          ? 'neutral'
                          : 'decrease'
                      }
                    >
                      {row.change30d}
                    </PriceChangeSpan>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={companyTableRows.length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={100}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </CompanyTableContainer>
  )
}
